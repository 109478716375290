import User from './User';
import { LayoutHeader } from '@sagano/share/components';
import { isProd } from '@sagano/train/utils/env';

const Header: React.FC = () => {
  const refresh = (e: React.MouseEvent<HTMLAnchorElement>) => {
    window.location.replace(e.currentTarget.href);
  };
  return (
    <LayoutHeader
      webTitle="Sagano Train"
      refreshHandle={refresh}
      isProd={isProd}
    >
      <User />
    </LayoutHeader>
  );
};

export default Header;
