import { computed } from 'mobx';

import { BaseAuth } from '@sagano/share/stores';

export class Auth extends BaseAuth {
  @computed
  get isTrain(): boolean {
    return this.userGroup === 'train';
  }

  @computed
  get isStation(): boolean {
    return this.userGroup === 'station';
  }

  @computed
  get canLoginUserGroup() {
    return this.isTrain || this.isStation;
  }
}

export default new Auth();
