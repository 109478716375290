/* eslint-disable react-refresh/only-export-components */
import { lazy, Suspense } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  useRouteError,
  Navigate
} from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import { BaseLayout } from '@sagano/train/layouts';

const NotFound = lazy(() => import('@sagano/share/views/NotFound'));
function RootErrorBoundary() {
  const error = useRouteError() as Error;
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    location.reload();
  }
  return null;
}

const lazyView = (view: JSX.Element) => {
  return <Suspense fallback="Loading...">{view}</Suspense>;
};

const ScanView = lazy(() => import('@sagano/train/views/Scan'));
const SeatMap = lazy(() => import('@sagano/train/views/SeatMap'));
const Login = lazy(() => import('@sagano/train/views/Login'));
const ResetPassword = lazy(() => import('@sagano/train/views/ResetPassword'));
const TrainSelect = lazy(() => import('@sagano/train/views/TrainSelect'));
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<RootErrorBoundary />}>
      <Route element={<PrivateRoute />}>
        <Route path="/" element={<BaseLayout />}>
          <Route index element={<Navigate to="train-select" replace />} />
          <Route path="train-select" element={lazyView(<TrainSelect />)} />
          <Route path="seat" element={lazyView(<SeatMap />)} />
          <Route path="scan" element={lazyView(<ScanView />)} />
          <Route path="reset-password" element={lazyView(<ResetPassword />)} />
        </Route>
      </Route>
      <Route path="login" element={lazyView(<Login />)} />
      <Route path="*" element={lazyView(<NotFound />)} />
    </Route>
  )
);

export default router;
