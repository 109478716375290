import React from 'react';
import ReactDOM from 'react-dom/client';
import '@sagano/train/styles/index.css';
import '@sagano/train/i18n';
import App from '@sagano/train/App';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
